<template>
  <div style="flex-basis: 33.333333%">
    <v-card class="mx-auto my-12" max-width="374">

    <div v-if="cardPage === 1">
      <NewProduct :parentData='formData' @child-data="appendChildData" />
    </div>

    <div v-if="cardPage === 2">
      <GeneralPricing :parentData='formData' @child-data='appendChildData' />
    </div>

    <div v-if="cardPage === 3 && formData.pricingMethod === 'Per Person'">
      <SinglePricing :parentData='formData' @child-data='appendChildData' />
    </div>

    <div v-if="cardPage === 3 && formData.pricingMethod === 'Group'">
      <GroupPricing :parentData='formData' @child-data='appendChildData' />
    </div>

    <div v-if="cardPage === 4">
      <CompDetails :parentData='formData' @child-data='appendChildData' />
    </div>

    <v-card-text style="padding: 0 16px 9px 16px;">
    <p
      v-if='validationResponse.length > 0'
      style="border-left: 3px solid red; padding: 15px 0 15px 15px; margin: 0;"
      v-html="validationResponseFormatted"
    >
    </p>
    </v-card-text>

    <v-divider class="mx-4"></v-divider>

    <v-overlay
      :value="overlay"
      opacity="0.9"
      z-index="99"
    >
      <div style="max-width: 650px; min-height: 550px; width: 100%; background-color: #fff; color: #000; padding: 10px;">
        <v-row>
          <v-spacer></v-spacer>
          <v-btn color="#283c86" text @click="overlay = false">X</v-btn>
        </v-row>
        <div style="display: flex; flex-direction: column; align-items: center; width: 98%;">
          <div style="margin-left: 30px;">
            <h2 style="margin-bottom: 3px">Confirm Pricing</h2>
            <p class="popupConfText">
              {{ this.$store.state.supplierName }} is submitting your information including, but not limited to contact details,
              location, products, pricing, and booking information, to Group Travel Odyssey (GTO) for
              addition as a supplier record. {{ this.$store.state.supplierName }} agrees to the following:
              <br><br>
              <ul>
                <li>All updates to any submitted information are the responsibility of {{ this.$store.state.supplierName }}.</li>
                <li>
                  Any errors in data entry by {{ this.$store.state.supplierName }} that result in incorrect calculations
                  in pricing by the GTO users are the responsibility of {{ this.$store.state.supplierName }}.
                </li>
                <li>
                  The supplier, products, and pricing will be visible to the entire GTO community
                  unless you specify otherwise. Please note this request for each product and/or
                  price you wish to restrict access. Restriction of access is only available for
                  products and pricing and within the limits of the GTO system.
                </li>
                <li>
                  After {{ this.$store.state.supplierName }} saves a product and/or price in GTO,
                  {{ this.$store.state.supplierName }} will not be
                  able to edit it. If you notice a discrepancy, please contact GTO immediately
                  to deactivate the product and/or pricing.
                </li>
              </ul>
            </p>
            <v-checkbox
              light
              v-model="checkboxpo"
              :label=stringCheckbox
            ></v-checkbox>
            <v-row style="position: absolute; bottom: 50px; width: 91%;">
              <v-btn color="#40832F" @click="overlay = false">
                Cancel
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="#40832F" @click="addProduct" v-if="checkboxpo && !submitted">
                Submit For Review
              </v-btn>
              <div v-if="submitted" style="display: flex; justify-content: center; width: 155px;">
                <v-progress-circular indeterminate :width="2" color="#4390ce"></v-progress-circular>
              </div>
            </v-row>
          </div>
        </div>
        <br>
      </div>
    </v-overlay>

    <v-card-actions>
      <v-btn v-if="cardPage > 1" color="#4390ce" text @click="cardPage -= 1">Back</v-btn>
      <v-spacer></v-spacer>
      <v-btn v-if="cardPage < 4" color="#4390ce" text @click="nextPage">Next</v-btn>
      <v-btn v-if="cardPage===4 && !submitted" color="#4390ce" text @click="overlay = true">
        Submit For Review
      </v-btn>
    </v-card-actions>

    </v-card>
  </div>
</template>

<script>
import NewProduct from '../components/addproduct/NewProduct.vue';
import GeneralPricing from '../components/addproduct/GeneralPricing.vue';
import SinglePricing from '../components/addproduct/SinglePricing.vue';
import GroupPricing from '../components/addproduct/GroupPricing.vue';
import CompDetails from '../components/addproduct/CompDetails.vue';

export default {
  name: 'Add',
  components: {
    NewProduct, GeneralPricing, SinglePricing, GroupPricing, CompDetails,
  },
  data() {
    return {
      checkboxpo: false,
      overlay: false,
      submitted: false,
      cardPage: 1,
      validationResponse: [],
      formData: {},
      stringCheckbox: `By checking this box and submitting this request ${this.$store.state.supplierName} agrees to the above terms and conditions.`,
    };
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
    validationResponseFormatted() {
      return `<b>Please correct the following fields:</b> <br>${this.validationResponse.map((x) => `${x}`).join('<br>')}`;
    },
  },
  methods: {
    nextPage() {
      if (this.cardPage === 1) {
        this.validationResponse = [];
        if (!this.formData.productName) this.validationResponse.push('Must set a product name');
        if (!this.formData.productType || !this.formData.productType.length) this.validationResponse.push('Must select a product type');
        if (this.formData.activityDuration && !/[0-9]:[0-9]/.test(this.formData.activityDuration)) this.validationResponse.push('Activity Duration must be in HH:MM format.');
        if (!this.validationResponse.length) this.cardPage += 1;
      } else if (this.cardPage === 2) {
        this.validationResponse = [];
        if (!this.formData.priceType || !this.formData.priceType.length) this.validationResponse.push('Set a price type');
        if (!this.formData.pricingMethod) this.validationResponse.push('Select a pricing method');
        if (!this.formData.priceValidDates || this.formData.priceValidDates.length < 2) this.validationResponse.push('Set your price valid dates');
        if (this.formData.groupMinimum && !/[0-9]/.test(this.formData.groupMinimum)) this.validationResponse.push('Group minimum must be a number.');
        if (this.formData.groupMaximum && !/[0-9]/.test(this.formData.groupMaximum)) this.validationResponse.push('Group maximum must be a number.');
        if (!this.validationResponse.length) this.cardPage += 1;
      } else if (this.cardPage === 3 && this.formData.pricingMethod === 'Per Person') {
        this.validationResponse = [];
        if (this.formData.studentPrice && !/[0-9]/.test(this.formData.studentPrice)) this.validationResponse.push('Student Price must be a number.');
        if (this.formData.adultPrice && !/[0-9]/.test(this.formData.adultPrice)) this.validationResponse.push('Adult Price must be a number.');
        if (this.formData.childPrice && !/[0-9]/.test(this.formData.childPrice)) this.validationResponse.push('Child Price must be a number.');
        if (this.formData.seniorPrice && !/[0-9]/.test(this.formData.seniorPrice)) this.validationResponse.push('Senior Price must be a number.');
        // if (this.formData.veteranPrice && !/[0-9]/.test(this.formData.veteranPrice)) this.validationResponse.push('Veteran Price must be a number.');
        if (this.formData.tax && !/[0-9]/.test(this.formData.tax)) this.validationResponse.push('Tax must be a number.');
        if (this.formData.fee && !/[0-9]/.test(this.formData.fee)) this.validationResponse.push('Fee must be a number.');
        if (this.formData.perPersonFee && !/[0-9]/.test(this.formData.perPersonFee)) this.validationResponse.push('Per Person Fee must be a number.');
        if (this.formData.groupFee && !/[0-9]/.test(this.formData.groupFee)) this.validationResponse.push('Group Fee must be a number.');
        if (!this.validationResponse.length) this.cardPage += 1;
      } else if (this.cardPage === 3 && this.formData.pricingMethod === 'Group') {
        this.validationResponse = [];
        if (this.formData.tax && !/[0-9]/.test(this.formData.tax)) this.validationResponse.push('Tax must be a number.');
        if (this.formData.fee && !/[0-9]/.test(this.formData.fee)) this.validationResponse.push('Fee must be a number.');
        if (this.formData.perPersonFee && !/[0-9]/.test(this.formData.perPersonFee)) this.validationResponse.push('Per Person Fee must be a number.');
        if (this.formData.groupPrice && !/[0-9]/.test(this.formData.groupPrice)) this.validationResponse.push('Group Price must be a number.');
        if (!this.validationResponse.length) this.cardPage += 1;
      } else {
        this.cardPage += 1;
      }
    },
    logdata() {
      console.log(this.formData);
    },
    addProduct() {
      this.submitted = true;
      const formData = new FormData();
      formData.append('bodyObj', JSON.stringify(this.formData));
      formData.append('file', this.formData.file);
      formData.append('supportingdocs', this.formData.supportingdocs);
      console.log(this.formData);
      fetch('https://gto-supplier-portal-api.herokuapp.com/api/test-create-product', {
        method: 'POST',
        headers: { Authorization: `Bearer ${this.token}` },
        body: formData,
      })
        .then((res) => res.json())
        .then((response) => {
          console.log(response);
          if (response.msg === 'Product Added.') {
            console.log(response.response.data[0][3].value);
            return this.addPricing(response.response.data[0][3].value);
          }
          this.submitted = false;
          return this.$store.commit('notify', 'Failed to add product. Please try again. Make sure product image is less than 3MB.');
        })
        .catch(() => {
          this.submitted = false;
          this.$store.commit('notify', 'Failed to add product. Please try again. Make sure product image is less than 3MB.');
        });
    },
    addPricing(productId) {
      console.log(this.formData);
      const pricingData = { ...this.formData, productId };
      fetch('https://gto-supplier-portal-api.herokuapp.com/api/create-pricing', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${this.token}` },
        body: JSON.stringify(pricingData),
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.msg === 'Pricing Added.') {
            return this.$router.push({ path: '/products' });
          }
          console.log(response);
          this.$store.commit('notify', 'Failed to add pricing. Please try again on the products page.');
          return setTimeout(() => { this.$router.push({ path: '/products' }); }, 5000);
        })
        .catch(() => {
          this.$store.commit('notify', 'Failed to add pricing. Please try again on the products page.');
          return setTimeout(() => { this.$router.push({ path: '/products' }); }, 5000);
        });
    },
    appendChildData(params) {
      this.formData = { ...this.formData, ...params };
    },
  },
};
</script>

<style scoped>
.popupConfText {
  height: auto;
  font-size: 14px;
  overflow-y: auto;
}
@media only screen and (max-width: 600px) {
  .popupConfText {
    height: 300px;
    background-color: rgb(236, 236, 236);
    padding: 3px;
    border-radius: 3px;
  }
}
</style>
