<template>
  <v-card-text class="pb-0">
    <p class="display-1 text--primary" style="text-align: center;">
      Pricing
    </p>

    <p>Price Type / Choose Market*</p>
    <v-checkbox v-model="formData.priceType" label="Student/Youth" value="Student/Youth"
    style="margin-top:0">
    </v-checkbox>
    <v-checkbox v-model="formData.priceType" label="Adult/Senior" value="Adult/Senior"
    style="margin-top:0">
    </v-checkbox>
    <v-checkbox v-model="formData.priceType" label="FIT" value="FIT"
    style="margin-top:0">
    </v-checkbox>
    <v-checkbox v-model="formData.priceType" label="Incentive/MICE" value="Incentive/MICE"
    style="margin-top:0">
    </v-checkbox>
    <!-- <v-checkbox v-model="formData.priceType" label="Receptive" value="Receptive"
    style="margin-top:0">
    </v-checkbox> -->

    <v-radio-group
      style="margin-top:0px;"
      v-model="formData.pricingMethod"
      label="Pricing Method*"
    >
      <v-radio
        label="Group"
        value="Group"
      ></v-radio>
      <v-radio
        label="Per Person"
        value="Per Person"
      ></v-radio>
    </v-radio-group>

    <p>Price Valid Dates*</p>
    <v-date-picker
      v-model="formData.priceValidDates"
      :show-current="false"
      range
      no-title
      elevation="2">
    </v-date-picker>

    <br><br>
    Valid Start Date: {{formData.priceValidDates[0]}}
    <br>
    Valid End Date: {{formData.priceValidDates[1]}}
    <br><br>

    <v-text-field v-model="formData.groupMinimum" label="Group Minimum" type="number">
    </v-text-field>
    <v-text-field v-model="formData.groupMaximum" label="Group Maximum" type="number">
    </v-text-field>

  </v-card-text>
</template>

<script>
export default {
  name: 'GeneralPricing',
  props: {
    parentData: {
      type: Object,
    },
  },
  data() {
    return {
      formData: {
        priceType: this.parentData.priceType || [],
        pricingMethod: this.parentData.pricingMethod || '',
        priceValidDates: this.parentData.priceValidDates || [],
        groupMinimum: this.parentData.groupMinimum || '',
        groupMaximum: this.parentData.groupMaximum || '',
      },
      range: [],
    };
  },
  watch: {
    formData: {
      handler() {
        this.$emit('child-data', this.formData);
      },
      deep: true,
    },
  },
};
</script>
